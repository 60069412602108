<template>
  <nav class="nav-wrapper">
    <ul>
        <li :class="{ 'is-active': $route.path === '/' }" @click="handleToLink('/')">首页</li>
        <li>
            <a href="http://xinlizixun.aijiad.com" target="_blanck">课程学习</a>
        </li>
        <li>
            <a href="https://chaxun-zgxlwsxh.oxcoder.com/#/" target="_blanck">证书查询</a>
        </li>
        <li :class="{ 'is-active': $route.path === '/authorized' }" @click="handleToLink('/authorized')">联合机构</li>
        <li>
          <a href="http://xinlizixun.aijiad.com/portal/appdownload" target="_blanck">APP下载</a> 
        </li>
    </ul>
  </nav>
</template>

<script>
export default {
  methods: {
    handleToLink(link) {
      this.$router.push({
        path: link,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-wrapper {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background: rgba(0, 0, 0, 0.7);

  display: flex;
  justify-content: center;

  ul {
    height: 60px;
    display: flex;
    justify-content: center;

    li {
      width: 200px;
      list-style: none;
      text-align: center;
      color: #fff;
      font-size: 16px;

      a {
        color: #fff;
      }
    }
    .is-active {
      background: #b98862;
    }
  }
}
</style>
